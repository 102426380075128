<template>
  <div class="head">
    <div :class="centerInfo==true?'mauto':''">
      <div class="head_left">
        <div class="logo">
          <router-link :to="{ path: '/'}" exact><img src="~assets/logo.png" alt=""></router-link>
        </div>
        <ul>
          <li>
            <router-link :to="{ path: '/'}" exact><span>首页</span></router-link>
          </li>
          <li>
            <router-link :to="{ path: '/newest'}" exact><span>最新</span></router-link>
          </li>
          <li>
            <router-link :to="{ path: '/hot'}" exact><span>热门</span></router-link>
          </li>
          <li>
          
            <router-link :to="{ path: '/classification'}" exact><span>分类</span></router-link>
          </li>
        </ul>
      </div>
      <div class="head_right">
        <div class="search">
          <input @keyup.enter="onKeySearch" v-model="searchValue" type="" name="">
          <img @click="search" src="~assets/search_icon.png" alt="">
        </div>
        <a class="login"
          :href="'http://passport.ourgame.com/lzgamelogin!webhallb.do?from=video&nexturl='+url"
          v-if="$store.state.userName==''">登录</a>
        <span class="name" v-if="$store.state.userName!=''">您好：{{$store.state.userName}}</span>
         <router-link :to="{ path: '/course'}" exact>
          <a href="">教程</a>
         </router-link>
       
        <span @click="jump('/browseRecords')">足迹</span>
        <!-- <router-link :to="{ path: '/browseRecords'}"><span></span></router-link>-->
        <div class="news">
            <p v-if="newsNum!=0">{{newsNum}}</p>
            <span @click="jump('/news')">消息</span>
        </div>
      
        <!--<router-link :to="{ path: '/news'}"><span></span></router-link> -->
        <span class="release_btn" @click="jump('/secondary/releaseVideo')">发布视频</span>
        <!--<router-link:to="{ path: '/'}"></router-link:to=>-->
        <a v-if="$store.state.userName!=''"
          :href="'http://passport.ourgame.com/lzgamelogout!webhall.do?nexturl='+url"
          class="loginout">退出</a>
      </div>
    </div>
  </div>
</template>

<script>
import { myUnreadMsgCnt } from '@/api/authority'
  export default {
    name: "head",
    props: {
      // 'centerInfo'
      centerInfo: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        searchValue: '',
        url:'',
        newsNum:0
      }
    },
    mounted(){
      this.url = encodeURIComponent(window.location.href)
      console.log(this.url)
      this.myUnreadMsgCntFn()
    },
    methods: {
      search() {
        console.log(this.searchValue)
        if (this.searchValue == '') {
          return false
        }
        this.$router.push({
          path: '/search',
          query: {
            vl: this.searchValue,
            type:1
          }
        })
      },
      onKeySearch(){
        this.search()
      },
      // 未读消息数量
      myUnreadMsgCntFn(){
        myUnreadMsgCnt().then(response => {
          if(response.data.code == 0){
            this.newsNum = response.data.data
          }else{
            this.$message({
                'message': response.data.msg
              })
          }
        })
      },
      // 跳转消息页
      jump(url) {
        this.$store.dispatch('LOGIN_INFO', {
          'vue': this,
          'userName': this.$store.state.userName
        }).then(response => {
          if (response) {
            this.$router.push({
              path: url,
            })
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .head {
    height: 38px;
    padding: 16px 0px 16px 0px;
    position: fixed;
    top: 0px;
    width: 100%;
    left: 50%;
    z-index: 999;
    background: white;
    left: 0px;
    border-bottom: 1px solid #ebedf3;

    .head_left {
      float: left;

      .logo {
        float: left;
      }

      ul {
        float: left;
        margin-left: 15px;

        li {
          margin-left: 15px;
          line-height: 38px;
          float: left;

          a {
            color: black
          }

          .router-link-active {
            color: #ff8a00;
          }
        }

        // .checkNav {

        // }
      }
    }
  .loginout{
    padding-right:20px;
  }
    .head_right {
      float: right;
      color: #6c6c6c;
      .news{
        float:left;
        position: relative;
        >p{
          position: absolute;
          right:-7px;
          top:1px;
          width:15px;
          height:15px;
          border-radius:50%;
          text-align: center;
          line-height:15px;
          color:white;
          font-size:12px;
          z-index: 99;
          background: red;
        }
        span{
          position: relative;
          // z-index: 999;
        }
      }
      .search {
        width: 186px;
        height: 40px;
        background-color: #f0f0f0;
        border-radius: 20px;
        line-height: 40px;
        float: left;

        input {
          background-color: #f0f0f0;
          border: 0px;
          margin-left: 12px;
          width: 144px;
          height: 40px;
          float: left;
        }

        img {
          cursor: pointer;
          float: left;
          margin-top: 12px;
        }
      }

      a {
        color: #6c6c6c;
        float: left;
        line-height: 38px;
        margin-left: 15px;
      }

      span {
        margin-left: 15px;
        float: left;
        line-height: 38px;
        cursor: pointer;
      }

      .release_btn {
        float: left;
        line-height: 38px;
        margin-left: 15px;
        width: 100px;
        height: 37px;
        background-color: #ff8a00 !important;
        border-radius: 4px;
        color: white;
        text-align: center;
      }
    }
  }
</style>